<template>
  <section class="transfer">
    <h2 class="account-creation">Account Creation</h2>
    <form class="form" novalidate="true" autocomplete="off" @submit.prevent="validateBVN">
      <div class="form__group--three">
        <label for="bvn" class="form__label">
          <input
            type="tel"
            id="bvn"
            name="bvn"
            placeholder="Bank Verification Number"
            class="form__bvn"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @focus="showBVN = true"
            v-model.lazy="$v.form.bvn.$model"
            :class="{
              'is-invalid': submitted && $v.form.bvn.$error,
            }"
        /></label>
        <small class="bvn">*565*0# to get your BVN</small>
        <small class="error" v-if="submitted && !$v.form.bvn.required"
          >BVN is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.form.bvn.numeric"
          >Needs to be numeric only.</small
        >
      </div>

      <button type="submit" class="btn btn__red btn__group" @click="validateBVN">
        <loading v-if="loading" />
        <span>Validate BVN</span>
      </button>
    </form>
  </section>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import api from "@/api/api.js";
export default {
  data() {
    return {
      showBVN: false,
      submitted: false,
      loading: false,
      form: { bvn: "", dob: "" },
    };
  },
  validations: {
    form: {
      bvn: {
        required,
        numeric,
      },
      dob: { required },
    },
  },
  methods: {
    validateBVN() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.bvn.$invalid) {
        return;
      }
      let payload = {
        BVN: this.form.bvn,
      };
      this.loading = true;
      api
        .handleBvnEnquiry(payload)
        .then((response) => {
          this.loading = false;
          let responseMessage = response.Message;
          if (responseMessage === "Request Processed Successfully") {
            let bvnData = {
              userBVN: response.Data,
            };
            this.$store.commit("setInputBVN", this.form.bvn);
            this.$store.commit("setUserBVNData", bvnData);
            this.$router.push({ name: "Open Account" });
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error validating BVN please try again`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.form__bvn {
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 1.4rem 2rem;
  outline: none;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  margin-bottom: 0.8em;
}
.account-creation {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  color: #000000;
  word-break: break-word;
  padding-bottom: 1em;
}
.bvn {
  // color: black;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
}
</style>
